import { Component, Input } from '@angular/core';
import { FormControl, ReactiveFormsModule } from '@angular/forms';

@Component({
  selector: 'app-text-input-field',
  standalone: true,
  imports: [ReactiveFormsModule],
  templateUrl: './text-input-field.component.html',
})
export class TextInputFieldComponent {
  @Input() formControl: FormControl = new FormControl('');
  @Input() label: string = '';
  @Input() inputId: string = '';
  @Input() placeHolder: string = '';
  @Input() formSubmitted: boolean = false;
  @Input() inputType: string = 'text';

  showError: boolean = false;
  @Input() disabled: boolean = false;

}
