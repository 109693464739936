import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CreateProduct, UpdateProduct } from '../../../models/product/product';
import { Observable } from 'rxjs';
import { ResponseDTO } from '@models/index';
import { environment } from '@environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ProductService {

  constructor(private http:HttpClient) { }
  private baseUrl : string = environment.baseUrl;

  public createProduct(model:FormData):Observable<ResponseDTO>{
    return this.http.post<ResponseDTO>(this.baseUrl+'api/product', model)
  }
  
  public updateProduct(model:FormData):Observable<ResponseDTO>{
    return this.http.put<ResponseDTO>(this.baseUrl+'api/product', model)
  }

  public deleteProduct(id:number):Observable<ResponseDTO>{
    return this.http.delete<ResponseDTO>(this.baseUrl+'api/product/'+id)
  }

}
