<header class="flex justify-between">
  <!-- Logo -->
  @if(showLogo){
  <div>
    <a routerLink="/">
      <div class="flex justify-center items-center">
        <img src="../../../../assets/logo.png" class="h-6 md:h-8" alt="vendbloc_logo">
      </div>
    </a>
  </div>
  }
  @if(isAuthenticated){
  <!-- Links -->
  <div class="flex-col justify-end space-x-2 space-y-3 md:flex-row md:space-x-6 xl:space-x-8 md:items-center">
  </div>
  }
  <!-- auth buttons and cart -->
  <div class="flex items-center space-x-5">
    
    <!-- <i (click)="toggleComplaint()" class="pi pi-comment"></i> -->
    @if(showCart){
      <!-- Mobile cart icon (clickable) -->
      <span class="relative cursor-pointer pi pi-shopping-cart md:cursor-default" (click)="isMobile && cartEvent()" style="font-size: 25px;">
        <p-badge [value]="cartCount" class="absolute -right-3 -top-4 scale-90" [style]="{'background-color':'#FFB800'}" severity="warning"></p-badge>
      </span>        
    }
    @if(isAuthenticated){
    <!-- Profile dropdown -->
    <div class="inline-block relative text-left">
      <div (click)="op.toggle($event)" class="flex items-center">
        <div>
          <img alt="profile image" [src]=" '../../../../assets/user-avatar-profile.png'"
            class="object-cover w-4 h-4 rounded-full cursor-pointer md:w-6 md:h-6">
        </div>
        <div class="px-2 py-1 md:px-4 md:py-2">
          <p class="text-xs font-medium text-gray-900 md:text-sm">
            {{ user?.name?.split(' ')?.[0] || 'Guest' }}
          </p>
          <p class="text-xs text-gray-500 md:text-sm">{{user?.role}}</p>
        </div>
        <div class="px-2 py-1 md:px-4 md:py-2">
          <span class="pi pi-chevron-down"></span>
        </div>
      </div>

      <p-overlayPanel #op>
        <ng-template pTemplate="content">
          <div class="py-1" role="none">
            <!-- Add user info section -->
            <div class="px-4 py-2 border-b">
              <p class="text-sm font-medium text-gray-900">{{user?.name}}</p>
              <p class="text-sm text-gray-500">{{user?.email}}</p>
            </div>
            <!-- Existing menu items -->
            <a routerLink="/dashboard" class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
              role="menuitem">Dashboard</a>
            <a routerLink="/my-orders" class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
              role="menuitem">My Orders</a>
            <a routerLink="/become-dispatcher" class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
              role="menuitem">Become a dispatcher</a>
            <a (click)="logout()" class="block px-4 py-2 text-sm text-gray-700 cursor-pointer hover:bg-gray-100"
              role="menuitem">Logout</a>
          </div>
        </ng-template>
      </p-overlayPanel>
    </div>
    } @else {



    @if(currentRoute !== '/login' && currentRoute !== '/register' && currentRoute !== '/forgot-password' && currentRoute !== '/request-verification'){
    <!-- Show both buttons on other pages -->
    <a class="block text-white border-gray-500 auth_button bg-vendblocpurple" routerLink="/login">Login</a>
    } @else {
    <!-- Show only the opposite button on login/register pages -->
    <!-- <a class="block text-white border-gray-500 auth_button bg-vendblocpurple"
      [routerLink]="currentRoute === '/login' ? '/register' : '/login'">
      {{currentRoute === '/login' ? 'Register' : 'Login'}}
    </a> -->
    }
   
    }
  </div>

</header>


<p-dialog header="Contact Us" [(visible)]="complaintVisible" position="top" [modal]="true"
  [breakpoints]="{ '1000px': '90vw' }" [style]="{ width: '50vw' }" [draggable]="false" [resizable]="false"
  (onHide)="handleDialogClose()">
  <form [formGroup]="createComplaintForm" (submit)="createComplaint()">
    <div class="flex flex-col mt-1 space-y-2 w-full">
      <app-text-input-field [formSubmitted]="complaintFormSubmitted" ngDefaultControl inputType="email" label="Email"
        inputId="email" placeHolder="Enter Your email"
        [formControl]="createComplaintForm.controls.email"></app-text-input-field>
      <app-text-input-field [formSubmitted]="complaintFormSubmitted" ngDefaultControl inputType="text" label="Phone"
        inputId="phone" placeHolder="Enter Your phone number"
        [formControl]="createComplaintForm.controls.phone"></app-text-input-field>
      <app-text-input-field [formSubmitted]="complaintFormSubmitted" ngDefaultControl inputType="text" label="Subject"
        inputId="subject" placeHolder="Enter the subject of your message"
        [formControl]="createComplaintForm.controls.subject"></app-text-input-field>
      <app-text-input-field [formSubmitted]="complaintFormSubmitted" ngDefaultControl inputType="text" label="Message"
        inputId="message" placeHolder="Enter your message"
        [formControl]="createComplaintForm.controls.message"></app-text-input-field>
      <div class="flex justify-end items-end space-x-3 w-full">
        <button type="button" class="px-6 py-2 text-gray-700 bg-gray-200 rounded-sm" (click)="toggleComplaint()">
          Cancel
        </button>
        <button class="px-6 py-2 text-white rounded-sm bg-vendblocpurpleb" [disabled]="disableComplaintSubmission"
          type="submit">
          Submit
        </button>
      </div>
    </div>
  </form>
</p-dialog>