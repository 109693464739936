import { Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';

export interface ConfirmDialogData {
  title: string;
  message: string;
  confirmLabel: string;
  cancelLabel: string;
  confirmColor?: string;
  cancelColor?: string;
  cancelBorderColor?: string;
  cancelTextColor?: string;
}

@Injectable({
  providedIn: 'root'
})
export class ConfirmDialogService {
  private dialogSubject = new Subject<ConfirmDialogData | null>();
  dialogState$ = this.dialogSubject.asObservable();

  private resolvePromise: ((value: boolean) => void) | null = null;

  open(data: ConfirmDialogData): Promise<boolean> {
    this.dialogSubject.next(data);
    return new Promise<boolean>((resolve) => {
      this.resolvePromise = resolve;
    });
  }

  close(result: boolean) {
    this.dialogSubject.next(null);
    if (this.resolvePromise) {
      this.resolvePromise(result);
      this.resolvePromise = null;
    }
  }
}
